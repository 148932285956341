

@tailwind base;
@tailwind components;
@tailwind utilities;



pre {
  white-space: pre-wrap; /* Wraps text */
  word-wrap: break-word; /* Breaks long words */
}

.card{
  min-height: 300vh;
}
.page { 
  background-color: rgb(249, 249, 249);
  align-items: center;
  align-content: center;
  color:rgb(10, 11, 11);
  min-width: 100%;
  min-height: 100%;
  margin-top: 1px;
margin-bottom: 1px;
 padding-top: 1px;

}
.form{ 
  background-color: rgb(16, 2, 44);
  align-items: center;
  min-width: 100%;
  min-height: 100%;
  color: azure;
  font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
}

/* src/Components/Globe */



.globe-container {
  width: 300px;
  height: 300px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.globe {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background: radial-gradient(circle, #87ceeb, #4682b4);
  position: relative;
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.5);
  animation: spin 10s linear infinite;
}

.keywords {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
}

.keyword {
  position: absolute;
  color: #ffffff;
  font-size: 1.5rem;
  font-weight: bold;
  animation: fadeInOut 5s ease-in-out infinite;
}

.icons {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 2;
}

.icon {
  position: absolute;
  font-size: 2rem;
  animation: bounce 2s ease-in-out infinite;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes fadeInOut {
  0%, 100% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}

@keyframes bounce {
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px);
  }
}
